<template>
  <q-table
    row-key="id"
    :rows-per-page-label="$t('Rows per page')"
    :rows="data"
    :v-model:pagination="columns"
    :pagination="{ page: 1, rowsPerPage: 250, totalRowsNumber: data.length }"
    :title="$t(title)"
    virtual-scroll
    binary-state-sort
    flat
  >
    <template v-slot:body="props">
      <q-tr
        :props="props"
        class="clickable"
        @dblclick="handleClick(props.row)"
      >
        <q-td
          key="id"
          :props="props"
        >
          {{ props.row.id }}
        </q-td>

        <q-td
          key="state"
          :props="props"
        >
          <q-badge :color="stateColors[props.row.state]">
            {{ props.row.state }}
          </q-badge>
        </q-td>

        <q-td
          key="priority"
          :props="props"
        >
          {{ props.row.priority }}
        </q-td>

        <q-td
          key="finished"
          :props="props"
        >
          {{ !!props.row.finished }}
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script>
export default {
  name: 'DynamicAssemblageTasksCollection',
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      columns: [
        { label: this.$t('Task'), name: 'id', align: 'left' },
        { label: this.$t('Status'), name: 'state', align: 'left' },
        { label: this.$t('Priority'), name: 'priority', align: 'left' },
        { label: this.$t('Finished'), name: 'finished', align: 'left' }
      ],
      stateColors: {
        new: 'grey-4',
        confirmed: 'green',
        rejected: 'negative',
        complete: 'positive'
      }
    }
  },
  computed: {
    title () {
      if (this.rawData.options) {
        return this.rawData.options.title
      }

      return this.rawData.title || ''
    }
  },
  methods: {
    handleClick () {}
  }
}
</script>
